(function (jq, dell) {
    dell.Components = dell.Components || {};
    let SupportAssistCarousel = null;
    dell.Components.RecentlyViewedProducts = dell.Components.RecentlyViewedProducts || {};
    (function () {
        this.createRvpsHtml = (response) => {
            dell.Components.RecentlyViewedProducts.resposne = response;
            let recentlyViewedList = response.RecentlyViewedList;

            var rvpsEvent = new CustomEvent("rvpsData", { detail: { data: response } });
            window.dispatchEvent(rvpsEvent);

            let cmsContent = response.CMSContent;
            let i = 0, j;
            var html = '';
            $('.rvpsitem').remove();
            for (i = 0, j = recentlyViewedList.length; i < j; i++) {
                html = html + `<div class="carousel-item rvpsitem" role="complementary" aria-label="Recently Viewed Product-item-${(i + 1)} of ${recentlyViewedList.length}">
                    <button class="btn slide-toggle" aria-label="Remove ${recentlyViewedList[i].ProductName}" id="btn-recentlyVP1-${i}" type="button" data-Service-tag="${recentlyViewedList[i].EncryptServiceTag}" data-IsTag='${recentlyViewedList[i].IsTag}' data-ProductCode='${recentlyViewedList[i].ProductCode}' data-TagId='${recentlyViewedList[i].TagId}'
                      data-IsEmcProduct='${recentlyViewedList[i].IsEmcProduct}' data-Serial-Number='${(recentlyViewedList[i].SerialNumber === undefined || recentlyViewedList[i].SerialNumber === null) ? '' : recentlyViewedList[i].SerialNumber}' data-AppName='${response.AppName}'>
                      <span aria-hidden="true"><svg class="dti"><svg id="dt-close" viewBox="0 0 32 32"><path d="M28.971 4.361l-1.331-1.331-11.637 11.637-11.56-11.561-1.331 1.331 11.56 11.561-11.484 11.484 1.331 1.331 11.484-11.484 11.561 11.56 1.331-1.331-11.561-11.56z"></path></svg></svg></span>
                    </button>
                  <div class="product-container"><div class="product-image">
                      <a href="${recentlyViewedList[i].TargetUrl}" id="anc-recentlyVP1-${i}" data-Service-tag="${recentlyViewedList[i].ServiceTag}" data-IsTag="${recentlyViewedList[i].IsTag}" data-ProductCode="${recentlyViewedList[i].ProductCode}" data-EncryptServiceTag="${recentlyViewedList[i].EncryptServiceTag}"  data-IsEmcProduct="${recentlyViewedList[i].IsEmcProduct}" data-SerialNumber="${recentlyViewedList[i].SerialNumber}">
                        <img alt= "${recentlyViewedList[i].ProductName} image" src="${recentlyViewedList[i].ImagePath !== null && recentlyViewedList[i].ImagePath !== '' ? recentlyViewedList[i].ImagePath : cmsContent.EmptyImageUrl}" title="${recentlyViewedList[i].ProductName}" style="max-width:90px;height:auto;" onerror="ProductImageSrcFallback(this,'${cmsContent.EmptyImageUrl}')">
                      </a></div>
                    <div class="product-details"><span class="product-badge badge badge-pill badge-teal">${cmsContent.RecentlyViewedHeader}</span>
                      <p class="product-name"><a href="${recentlyViewedList[i].TargetUrl}"  id="anc-recentlyVP2-${i}" data-Service-tag="${recentlyViewedList[i].ServiceTag}" data-IsTag="${recentlyViewedList[i].IsTag}" data-ProductCode="${recentlyViewedList[i].ProductCode}" data-EncryptServiceTag="${recentlyViewedList[i].EncryptServiceTag}" data-IsEmcProduct="${recentlyViewedList[i].IsEmcProduct}" data-SerialNumber="${recentlyViewedList[i].SerialNumber}"> ${recentlyViewedList[i].ProductName !== null && recentlyViewedList[i].ProductName !== '' ? recentlyViewedList[i].ProductName : ''}</a></p>
                      ${(() => {
                        if (recentlyViewedList[i].IsEmcProduct && recentlyViewedList[i].SerialNumber !== null && recentlyViewedList[i].SerialNumber !== '') {
                            return `<p class="product-identifier">${cmsContent.ProductCode}: ${recentlyViewedList[i].SerialNumber}</p>`;
                        }
                        else if (recentlyViewedList[i].IsTag) {
                            return `<p class="product-identifier">${cmsContent.ServiceTag} ${recentlyViewedList[i].ServiceTag}</p>`;
                        }
                        else {
                            return ``;
                        }
                    })()}
                    </div>
                  </div>
                  </div>`;

            }
            return html;
        };

        this.renderView = (html, noThisPc) => {
            let detectPcCarousel = document.getElementById('detectPcCarousel');
            let carouselInner = detectPcCarousel.querySelector('.carousel-inner');
            carouselInner.insertAdjacentHTML('beforeend', html);
            if (noThisPc) {
                detectPcCarousel.querySelectorAll('.carousel-item')[0].classList.add('active');
            }
            $('#detectPcCarousel').carousel('dispose');
            $('#detectPcCarousel').carousel({
                pause: false,
                ride: false,
                wrap: false
            });
            SupportAssistCarousel = new EsSaCarousel('#detectPcCarousel');
            var selector = '#detectPcCarousel .carousel-item';
            if ($(selector).length == 1) {
                $(selector).removeClass("d-flex justify-content-center w-100");
                $(selector).addClass("d-flex justify-content-center w-100");
            } else {
                $(selector).removeClass("d-flex justify-content-center w-100");
            }
            $("button[id^=btn-recentlyVP1-]").each(function (i, elem) {
                $(elem).unbind("click").click(function (event) {
                    Dell.Components.RecentlyViewedProducts.removeProduct($(this).attr("data-Service-tag"),
                        $(this).attr("data-IsTag"),
                        $(this).attr("data-ProductCode"),
                        $(this).attr("data-TagId"),
                        $(this).attr("data-IsEmcProduct"),
                        $(this).attr("data-Serial-Number"),
                        $(this).attr("data-AppName"),
                        event);
                });
            });
            $("a[id^=anc-recentlyVP]").each(function (i, elem) {
                $(elem).unbind("click").click(function () {
                    Dell.Components.RecentlyViewedProducts.rvpsCallBack($(this).attr("data-Service-tag"),
                        $(this).attr("data-IsTag"),
                        $(this).attr("data-ProductCode"),
                        $(this).attr("data-EncryptServiceTag"),
                        $(this).attr("data-IsEmcProduct"),
                        $(this).attr("data-SerialNumber"));
                });
            });
        };

        var createRvpsHtmlString = this.createRvpsHtml;

        this.getRVPS = () => {
            let urlPath = this.Url();
            let url = `${urlPath.baseUrl}/${urlPath.getRVPS}`;
            this.makeAjaxCall(url, "GET")
                .done(function (response) {
                    let htmltstring = createRvpsHtmlString(response);
                    renderView1(htmltstring);
                }).fail(function () {
                    console.error('rvps api failed');
                });
        };

        this.Url = () => {
            if (Dell.OnlineUtils !== 'undefined' && Dell.OnlineUtils.Lwp !== 'undefined') {
                let baseUrl = 'https://www.dell.com/support/components';
                if (Dell.ComponentLoader.baseUrls !== undefined && Dell.ComponentLoader.baseUrls["rvpsApi"]) {
                    baseUrl = Dell.ComponentLoader.baseUrls["rvpsApi"];
                }
                let urls = {
                    baseUrl: baseUrl,
                    getRVPS: 'rvps/getrvps?appName=' + Dell.ComponentLoader.appNames["rvps"],
                    removeRVPS: 'rvps/removervps'
                };
                return urls;
            }
            return '';
        };


        this.makeAjaxCall = (url, methodType, data) => {
            let ajaxData = {
                url: url,
                method: methodType,
                dataType: "json",
                cache: false,
                contentType: "application/json;",
                async: true
            };

            if (data !== undefined || data !== null) {
                ajaxData.data = data;
            }

            if (url.indexOf("//.dell.com") === -1) {
                ajaxData.xhrFields = {
                    withCredentials: true
                };
            }

            return jq.ajax(ajaxData);
        };

        var renderView1 = this.renderView;

        this.rvpsCallBack = (serviceTag, isTag, productCode, encryptServiceTag, isEmcProduct, serialNumber) => {
            if (typeof window.RVPSCallBack !== "undefined") {
                let callBackFun = window.RVPSCallBack;
                let data = {
                    "serviceTag": serviceTag,
                    "isTag": isTag,
                    "productCode": productCode,
                    "encryptServiceTag": encryptServiceTag,
                    "isEmcProduct": isEmcProduct,
                    "serialNumber": serialNumber
                };
                let returnObj = callBackFun.call(this, data);
                if (returnObj !== undefined && returnObj === false) {
                    event.preventDefault();
                }
            }
        };

        this.removeProduct = (serviceTag, isTag, productCode, tagId, isEmcProduct, serialNumber, source, event) => {
            let urlPath = this.Url();
            let url = `${urlPath.baseUrl}/${urlPath.removeRVPS}`;
            let data = {
                "ServiceTag": serviceTag,
                "IsTag": isTag,
                "ProductCode": productCode,
                "IsEmcProduct": isEmcProduct,
                "serialNumber": serialNumber,
                "TagId": tagId,
                "Source": source
            };


            this.makeAjaxCall(url, "POST", JSON.stringify(data))
                .done(function (response) {
                    var rvpsRemoveEvent = new CustomEvent("rvpsRemoveData", { detail: { data: data } });
                    window.dispatchEvent(rvpsRemoveEvent);
                    SupportAssistCarousel._removeSlideSuccess(event);
                    var length = $(".rvpsitem").length;
                    $(".rvpsitem").each(function (index) {
                        $(this).attr('aria-label', `Recently Viewed Product-item-${index + 1} of ${length}`);
                    });
                    var selector = '#detectPcCarousel .carousel-item';
                    if ($(selector).length == 1) {
                        $(selector).removeClass("d-flex justify-content-center w-100");
                        $(selector).addClass("d-flex justify-content-center w-100");
                        if (window.detectPcSaUnSupportedBrowser == 1) {
                            $('#notSupportedBrowser').css('display', 'table-cell');
                        }
                    }
                }).fail(function () {
                    console.error('rvps api failed');
                    return false;
                }).always(function () {
                });
        };

        this.removCurrentSlide = () => {
        };

        this.checkAndRemoveEmptySlide = () => {
        };

        this.checkAndHideIntegratedRvpsDivModified = () => {
            if (typeof (window.RvpsTryVisit) === "undefined") {
                $('.detect-pc-widget').css('display', 'none');
            } else {
                $("#divNoRvpsMsgs").remove();
                $('#sa-progress').remove();
                $("#detectPcHeader").html(window.RvpsNoProducts);
                var lwp = Dell.Metrics.sc.language + "-" + Dell.Metrics.sc.country;
                window.RvpsKbUrl = window.RvpsKbUrl.replace('{0}', lwp);
                var kbMsg = window.RvpsTryVisit.replace('{0}', `<a href="${window.RvpsKbUrl}">${window.RvpsKbText}</a>`);
                var html = `<p class="text-center" id='divNoRvpsMsgs'>${kbMsg}</p>`;
                $("#detectPcHeader").after(html);
            }
            if (typeof window.EmptyDetectPcWidget !== "undefined") {
                let callBackFun = window.EmptyDetectPcWidget;
                callBackFun.call(this, null);
            }
        };

    }).apply(dell.Components.RecentlyViewedProducts);
})(jQuery, Dell = window.Dell || {});

function ProductImageSrcFallback(self, fallBackSrc) {
    self.src = fallBackSrc;
    self.onerror = null;
}