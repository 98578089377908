(function (jq, dell) {
    dell.Components = dell.Components || {};
    dell.Components.RecentlyPurchasedProducts = dell.Components.RecentlyPurchasedProducts || {};
    (function () {

        this.createRppsHtml = (response) => {
            let purchasedProd = response.Result;
            let purchasedProdList = purchasedProd.Assets;
            let cmsContent = purchasedProd.ServiceTagLabel;           
            let i = 0, j;
            var defaultImagePath = "https://i.dell.com/is/image/DellContent/content/dam/global-site-design/non-product_images/icons/no_product_image.svg";
            var html = '';
            $('.rppsitem').remove();
            for (i = 0, j = purchasedProdList.length; i < j; i++) {
                html = html + `<div class="carousel-item rppsitem"><div class="product-container"><div class="product-image"><a href="${purchasedProdList[i].TargetUrl}" id="anc-ppltu-${i}" data-et="${purchasedProdList[i].EsvcTag}" data-ept="${purchasedProdList[i].EPsvcTag}">
                    <img alt="${purchasedProdList[i].ProductName} image" src="${purchasedProdList[i].ImageUrl}" title="${purchasedProdList[i].ProductName}" style="max-width:90px;height:auto;" onerror="ProductImageSrcFallback(this,'${defaultImagePath}')">
                  </a></div><div class="product-details"><span class="product-badge badge badge-pill badge-purple">${purchasedProd.Title}</span><p class="product-name"><a href="${purchasedProdList[i].TargetUrl}" id="anc-ppltu1-${i}" data-et="${purchasedProdList[i].EsvcTag}" data-ept="${purchasedProdList[i].EPsvcTag}">${purchasedProdList[i].ProductName}</a></p><p class="product-identifier">${cmsContent} ${purchasedProdList[i].EsvcTag}</p></div></div></div>`;
            }
            return html;
        };

        this.renderView = (html, noThisPc, rvpsCount) => {
            let detectPcCarousel = document.getElementById('detectPcCarousel');
            let carouselInner = detectPcCarousel.querySelector('.carousel-inner');
            carouselInner.insertAdjacentHTML('beforeend', html);
            if (noThisPc) {
                detectPcCarousel.querySelectorAll('.carousel-item')[0].classList.add('active');
            }
            if (rvpsCount === 0) {
                var detectPc = $('#detectPcCarousel');
                detectPc.carousel('dispose');
                detectPc.carousel({
                    pause: false,
                    ride: false,
                    wrap: false
                });
                const supportAssistCarousel = new EsSaCarousel('#detectPcCarousel');
            }
            var selector = '#detectPcCarousel .carousel-item';
            if ($(selector).length == 1) {
                $(selector).removeClass("d-flex justify-content-center w-100");
                $(selector).addClass("d-flex justify-content-center w-100");
            }
            else {
                $(selector).removeClass("d-flex justify-content-center w-100");
            }
            $("a[id^=anc-ppltu]").each(function (i, elem) {
                $(elem).unbind("click").click(function () { Dell.Components.RecentlyPurchasedProducts.purchasedproductsCallBack($(this).attr("data-et"), $(this).attr("data-ept")); });
            });
        };

        var createRppsHtmlString = this.createRppsHtml;
        var renderView1 = this.renderView;
        this.getPurchasedProducts = () => {
            let urlPath = this.Url();
            let url = `${urlPath.baseUrl}/${urlPath.getPurchasedproducts}`;
            this.makeAjaxCall(url, "GET")
                .done(function (response) {
                    let html = createRppsHtmlString(response);
                    renderView1(html);
                }).fail(function () {
                    console.error('Purchased Product api failed');
                });
        };

        this.Url = () => {
            if (Dell.OnlineUtils !== 'undefined' && Dell.OnlineUtils.Lwp !== 'undefined') {
                let baseUrl = 'https://www.dell.com/support/components';
                if (Dell.ComponentLoader.baseUrls !== undefined && Dell.ComponentLoader.baseUrls["rpps"]) {
                    baseUrl = Dell.ComponentLoader.baseUrls["rpps"];
                }
                let urls = {
                    baseUrl: baseUrl,
                    getPurchasedproducts: 'purchasedproducts/products?appName=' + Dell.ComponentLoader.appNames["rpps"]
                };
                return urls;
            }
            return '';
        };

        this.purchasedproductsCallBack = (serviceTag, encServiceTag) => {
            if (typeof window.PurchasedproductsCallBack !== "undefined") {
                let callBackFun = window.PurchasedproductsCallBack;
                let data = {
                    "serviceTag": serviceTag,
                    "encryptedServiceTag": encServiceTag
                };
                let returnObj = callBackFun.call(this, data);
                if (returnObj !== undefined && returnObj === false) {
                    event.preventDefault();
                }
            }
        };

        this.makeAjaxCall = (url, methodType) => {
            if (Dell.ComponentLoader.isAuth) {
                let ajaxData = {
                    url: url,
                    method: methodType,
                    dataType: "json",
                    cache: false,
                    contentType: "application/json;",
                    async: true
                };

                if (url.indexOf("//.dell.com") === -1) {
                    ajaxData.xhrFields = {
                        withCredentials: true
                    };
                }
                return jq.ajax(ajaxData);
            } else {
                let dfd = $.Deferred();
                dfd.resolve();
                return dfd.promise();
            }
        };

    }).apply(dell.Components.RecentlyPurchasedProducts);
})(jQuery, Dell = window.Dell || {});