/**
 * ----------------------------------------------------
 * Polyfills
 * ----------------------------------------------------
 */

// 
// Element.closest polyfill for IE
// 

if (!Element.prototype.matches) {
    Element.prototype.matches = Element.prototype.msMatchesSelector ||
        Element.prototype.webkitMatchesSelector;
}

if (!Element.prototype.closest) {
    Element.prototype.closest = function (s) {
        var el = this;

        do {
            if (Element.prototype.matches.call(el, s)) return el;
            el = el.parentElement || el.parentNode;
        } while (el !== null && el.nodeType === 1);
        return null;
    };
}

// 
// forEach on NodeList polyfill for IE
// 

if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = Array.prototype.forEach;
}

//
// Node.remove polyfill for IE
//

(function (arr) {
    arr.forEach(function (item) {
        if (item.hasOwnProperty('remove')) {
            return;
        }
        Object.defineProperty(item, 'remove', {
            configurable: true,
            enumerable: true,
            writable: true,
            value: function remove() {
                if (this.parentNode === null) {
                    return;
                }
                this.parentNode.removeChild(this);
            }
        });
    });
})([Element.prototype, CharacterData.prototype, DocumentType.prototype]);

/**
 * ----------------------------------------------------
 * Constants
 * ----------------------------------------------------
 */

const SaCarouselSelector = {
    CAROUSEL_ITEM: '.carousel-item',
    CAROUSEL_CONTROL_PREV: '.carousel-control-prev',
    CAROUSEL_CONTROL_NEXT: '.carousel-control-next',
    SLIDE_TOGGLE: '.slide-toggle'
}

const SaCarouselClassName = {
    DISABLED: 'disabled'
}

/**
 * ----------------------------------------------------
 * Class Definition
 * ----------------------------------------------------
 */

class EsSaCarousel {
    constructor(element) {
        this._element = document.querySelector(element);

        // Setting up _allSlides as a true array
        // due to '.splice' call in the _removeSlide() method
        const allSlidesNodeList = this._element.querySelectorAll(SaCarouselSelector.CAROUSEL_ITEM);
        this._allSlides = Array.prototype.slice.call(allSlidesNodeList);

        this._removeSlideBtns = this._element.querySelectorAll(SaCarouselSelector.SLIDE_TOGGLE);

        this._prevBtn = this._element.querySelector(SaCarouselSelector.CAROUSEL_CONTROL_PREV);
        this._nextBtn = this._element.querySelector(SaCarouselSelector.CAROUSEL_CONTROL_NEXT);

        // Needed to update the carousel control functionality
        // based on viewport size
        this._currentSlide;
        this._viewportWidth;
        this._filmstripView;
        this._setViewportWidth();
        this._handleViewportChange();

        window.addEventListener('resize',
            this._debounce(() => {
                    window.requestAnimationFrame(() => {
                        this._setViewportWidth();
                        this._handleViewportChange();
                    });
                },
                250));
        if (this._currentSlide === undefined) {
            this._currentSlide = 0;
        }

        this._bindSlidHandler();       
    }

    // Private
    _toggleCarouselControls(evt) {
        // Is first slide active? Disable prevBtn
        if (evt.to === 0) {
            this._prevBtn.classList.add(SaCarouselClassName.DISABLED);

            // Is first slide active and more than one slide? Enable nextBtn
            if (this._allSlides.length > 1) {
                this._nextBtn.classList.remove(SaCarouselClassName.DISABLED)
            }
        }

        ////added to enable next button
        if (evt.to == undefined && this._allSlides.length > 2 && this._filmstripView) {
            this._nextBtn.classList.remove(SaCarouselClassName.DISABLED)
        }

        ////added to enable next button mobile device
        if (evt.to == undefined && this._allSlides.length == 2 && !this._filmstripView) {
            this._nextBtn.classList.remove(SaCarouselClassName.DISABLED)
        }

        ///added for screen resize next button disble only for two slides
        if (evt.to == undefined && this._allSlides.length == 2 && this._filmstripView) {
            this._nextBtn.classList.add(SaCarouselClassName.DISABLED)
        }
        ///Mobile view more than one item refresh 
        if (evt.to == undefined && this._allSlides.length >2 && !this._filmstripView) {
            this._nextBtn.classList.remove(SaCarouselClassName.DISABLED)
        }

        // Is only 1 slide? Disable nextBtn
        if (this._allSlides.length === 1) {
            //added to make center for only one slide
            //var EmcOrMobile = $('#EmcOrMobile').val();
            //if (EmcOrMobile==="false") {
            var elmnt = $("#detectPcCarousel div.carousel-item");
            if (elmnt !== undefined && elmnt.hasClass('active')) {
                elmnt.addClass("d-flex justify-content-center w-100");
            }
            if (elmnt !== undefined && elmnt.hasClass('carousel-item carousel-item-prev carousel-item-right')) {
                elmnt.removeClass("carousel-item-prev carousel-item-right");
                elmnt.addClass("carousel-item active d-flex justify-content-center w-100");
            }

            var elmntSa = $("#divAutoDetectPc  div.sa-recommended-slide");
            if (elmntSa !== undefined && elmntSa.hasClass('sa-recommended-slide') ) {
                var outerElmnt = $("#divAutoDetectPc div.product-container");
                if (outerElmnt !== undefined) {
                    outerElmnt.removeClass("product-container").addClass("text-center");
                    elmntSa.removeClass("sa-recommended-slide");
                }
            }

            this._nextBtn.classList.add(SaCarouselClassName.DISABLED);
        }
        if (this._allSlides.length === 0 && $('#EmcOrMobile').val() === "true") {
            // $('.detect-pc-widget').css('display', 'none');
            Dell.Components.RecentlyViewedProducts.checkAndHideIntegratedRvpsDivModified();
        }

        // Is second+ slide active? Enable prevBtn
        if (evt.to > 0) {
            this._prevBtn.classList.remove(SaCarouselClassName.DISABLED);

            // Is second+ slide active and more slides available? enable nextBtn
            if (this._allSlides.length > evt.to + (this._filmstripView ? 1 : 0)) {
                this._nextBtn.classList.remove(SaCarouselClassName.DISABLED);
            }
        }

        // Is last slide active? disable nextBtn?
        if (evt.to === this._allSlides.length - (this._filmstripView ? 2 : 1)) {
            this._nextBtn.classList.add(SaCarouselClassName.DISABLED);
        }
    }

    _removeSlide(evt) {
        if (evt.currentTarget.attributes["data-Service-tag"] !== undefined) {
            //this._removeProductAPi(evt.currentTarget.attributes["data-Service-tag"].value, evt.currentTarget.attributes["data-IsTag"].value, evt.currentTarget.attributes["data-ProductCode"].value, evt.currentTarget.attributes["data-TagId"].value, evt.currentTarget.attributes["data-IsEmcProduct"].value, evt.currentTarget.attributes["data-Serial-Number"].value, evt.currentTarget.attributes["data-AppName"].value, evt.currentTarget.attributes["data-Rvps-url"].value, evt);
            Dell.Components.RecentlyViewedProducts.removeProduct(evt.currentTarget.attributes["data-Service-tag"].value,
                evt.currentTarget.attributes["data-IsTag"].value,
                evt.currentTarget.attributes["data-ProductCode"].value,
                evt.currentTarget.attributes["data-TagId"].value,
                evt.currentTarget.attributes["data-IsEmcProduct"].value,
                evt.currentTarget.attributes["data-Serial-Number"].value,
                evt.currentTarget.attributes["data-AppName"].value,
                evt);
        }

    }

    _ErrorRemove(evt) {
        $(this._element).carousel('dispose');
        $(this._element).carousel({
            pause: false,
            ride: false,
            wrap: false
        });

        this._bindSlidHandler();
    }

    _removeSlideSuccess(evt) {
        let slide = evt.target.closest('.carousel-item');
        let slideIndex = this._allSlides.indexOf(slide);
        this._allSlides.splice(slideIndex, 1);

        /*
        *  Move carousel depending on which slide was removed
        *  And how many remain
        *  and Update event object to reflect currently
        */
        // Is the currently active slide being removed?
        let event = { to: undefined };
        if (slideIndex === this._currentSlide) {
            // Are there more slides available?
            if (slideIndex !== this._allSlides.length) {
                // Move to next slide
                $(this._element).carousel(slideIndex + 1);
                event.to = this._currentSlide;
            } else {
                $(this._element).carousel(slideIndex - 1);
                this._currentSlide = slideIndex - 1;
                event.to = slideIndex - 1;
            }
        } else {
            event.to = this._currentSlide;
        }

        slide.remove();

        this._toggleCarouselControls(event);

        // Re-initializes carousel so that it acurrately reflects
        // the new number of slides in the carousel
        $(this._element).carousel('dispose');
        $(this._element).carousel({
            pause: false,
            ride: false,
            wrap: false
        });

        this._bindSlidHandler();

    }
    
    _bindSlidHandler() {
        $(this._element).on('slid.bs.carousel',
            (evt) => {
                this._currentSlide = evt.to;
                this._toggleCarouselControls(evt);
            });
    }

    _debounce(func, wait) {
        let timeout;

        return function executedFunction(...args) {
            const later = () => {
                clearTimeout(timeout);
                func(...args);
            };

            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
        };
    }

    _handleViewportChange() {
        if (this._filmstripView && this._viewportWidth < 1200) {
            this._filmstripView = false;
        } else if (!this._filmstripView && this._viewportWidth >= 1200) {
            this._filmstripView = true;
        }

        let event = {
            to: this._currentSlide
        }
        this._toggleCarouselControls(event);
    }

    _setViewportWidth() {
        this._viewportWidth = window.innerWidth || document.documentElement.clientWidth;
    }

}