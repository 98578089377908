(function ($, dell) {
    dell.Components = dell.Components || {};
    dell.Components.DetectProductRppsRvps = dell.Components.DetectProductRppsRvps || {};

    (function () {

        this.Init = () => {
            performance.mark("esup-homecards-start");
            let progressEl = document.getElementById('sa-progress');
            let progressVal = progressEl.querySelector('.progress-bar');
            $('.detect-pc-widget').css('display', 'flex');
            progressVal.style.width = '50%';
            this.getDetectPcRvpsRpps();
        };

        this.reInit = () => {
            this.Init();
        };

        this.getDetectPcRvpsRpps = () => {

            //let componentLoader = Dell.ComponentLoader;
            var detectProduct = Dell.Components.DetectProduct;
            var noThisPc = false;
            Dell.ComponentLoader.appNames["detect-product"] = Dell.ComponentLoader.appNames["detectproduct-rvps-rpps-v1"];
            Dell.ComponentLoader.appNames["rvps"] = Dell.ComponentLoader.appNames["detectproduct-rvps-rpps-v1"];
            Dell.ComponentLoader.appNames["rpps"] = Dell.ComponentLoader.appNames["detectproduct-rvps-rpps-v1"];
            // call detect product
            //if (componentLoader.isEnterpriseUser === false && detectProduct.enableDetectMyProduct && !window.detectPcRvpsMobile) {
            if (detectProduct.enableDetectMyProduct && !window.detectPcRvpsMobile) {
                $("#EmcOrMobile").val("false");
                performance.mark("esup-thispc-start");
                detectProduct.callSa();
            } else {
                $("#EmcOrMobile").val("true");
                noThisPc = true;
                $("#firstCarslItem").remove();
                let detectResponse = {
                    serviceTag: '',
                    supportAssistInstalled: false
                };
                detectProduct.detectProductPromise.resolve(detectResponse);
            }

            // call Rvps and RPPS
            var rvps = Dell.Components.RecentlyViewedProducts;
            var rpps = Dell.Components.RecentlyPurchasedProducts;
            var rvpsCount = 0;
            var hasRVPS = false;
            var hasRPPS = false;
            var thisPC = false;
            var tagSource = "";
            let rvpsurlPath = rvps.Url();
            let rvpsurl = `${rvpsurlPath.baseUrl}/${rvpsurlPath.getRVPS}`;
            let rppsurlPath = rpps.Url();
            let rppsurl = `${rppsurlPath.baseUrl}/${rppsurlPath.getPurchasedproducts}`;
            var thisPCserviceTag = '';
            var SAerror = null;

            let progressEl = document.getElementById('sa-progress');
            let progressVal = progressEl.querySelector('.progress-bar');
            var appNameApp = Dell.ComponentLoader.appNames["detectproduct-rvps-rpps-v1"];
            var appNameLoad = appNameApp !== undefined ? appNameApp.toLowerCase() : "";

            $.when(detectProduct.detectProductPromise.promise(),
                rvps.makeAjaxCall(rvpsurl, "GET"),
                rpps.makeAjaxCall(rppsurl, "GET"))
                .done(function (detectProductResponse, rvpsResponse, rppsResponse) {
                    let rvpsHtml = '';
                    let rppsHtml = '';
                    thisPC = detectProductResponse.serviceTag !== '';
                    tagSource = detectProductResponse.tagSource;
                    thisPCserviceTag = detectProductResponse.serviceTag;
                    if (typeof detectProductResponse.SAerror !== 'undefined')
                        SAerror = detectProductResponse.SAerror;
                    if ($("#EmcOrMobile").val() !== "true") {
                        var measureTitle = "esup-thispc-load-";
                        if (tagSource !== "") {
                            performance.mark("esup-thispc-end");
                            measureTitle = measureTitle + appNameLoad + "-" + tagSource
                            performance.measure(measureTitle, "esup-thispc-start", "esup-thispc-end");
                        }
                    }
                    if (rppsResponse !== undefined && rppsResponse !== null && rppsResponse[0] !== null &&
                        rppsResponse[0].Result !== undefined &&
                        rppsResponse[0].Result.Assets !== undefined &&
                        rppsResponse[0].Result.Assets.length > 0) {

                        if (detectProductResponse.serviceTag !== '') {
                            rppsResponse[0].Result.Assets =
                                dell.Components.DetectProductRppsRvps.RemoveDetectTagFromRPPS(
                                    rppsResponse[0].Result.Assets,
                                    detectProductResponse.serviceTag);
                        }
                        rppsHtml = rpps.createRppsHtml(rppsResponse[0]);
                        hasRPPS = rppsResponse[0].Result.Assets.length > 0;
                    }

                    if (rvpsResponse !== undefined && rvpsResponse !== null && rvpsResponse[0] !== null &&
                        rvpsResponse[0].RecentlyViewedList !== null &&
                        rvpsResponse[0].RecentlyViewedList.length > 0) {

                        if (detectProductResponse.serviceTag !== '') {
                            rvpsResponse[0].RecentlyViewedList =
                                dell.Components.DetectProductRppsRvps.RemoveDetectTagFromRVPS(
                                    rvpsResponse[0].RecentlyViewedList,
                                    detectProductResponse.serviceTag);
                        }
                        if (rppsHtml !== '') {
                            rvpsResponse[0].RecentlyViewedList =
                                dell.Components.DetectProductRppsRvps.FilterRPVS(rvpsResponse[0].RecentlyViewedList,
                                    rppsResponse[0].Result.Assets);
                        }
                        rvpsHtml = rvps.createRvpsHtml(rvpsResponse[0]);
                        rvpsCount = rvpsResponse[0].RecentlyViewedList.length;
                        hasRVPS = rvpsResponse[0].RecentlyViewedList.length > 0;
                    }
                    if (rppsHtml !== '' || rvpsHtml !== '') {
                        $("#NosaFv").val("false");
                        if (rppsHtml !== '') {
                            rpps.renderView(rppsHtml, noThisPc, rvpsCount);
                            noThisPc = false;
                        }
                        if (rvpsHtml !== '') {
                            rvps.renderView(rvpsHtml, noThisPc);
                        }
                    } else {
                        if (rppsHtml == '' && rvpsHtml == '') {
                            $("#NosaFv").val("true");
                        }
                    }
                }).fail(function () {
                    console.error('rvps rpps failed');
                    progressEl.classList.add('d-none');
                    progressEl.classList.remove('d-flex');
                }).always(function () {
                    Dell.Components.DetectProductRppsRvps.IntegratedRVPSLoaded(rvpsCount);
                    Dell.Components.DetectProductRppsRvps.IntegratedPCRVPSPurchaseProdsLoaded(hasRVPS, hasRPPS, thisPC, thisPCserviceTag, tagSource, SAerror);
                    if ($('#detectPcCarousel .carousel-item').length == 1) {
                        $("#detectPcCarousel .carousel-item").removeClass("d-flex justify-content-center w-100");
                        $("#detectPcCarousel .carousel-item").addClass("d-flex justify-content-center w-100");
                        if (window.detectPcSaUnSupportedBrowser == 1) {
                            $('#notSupportedBrowser').css('display', 'table-cell');
                        }     
                    }
                    else {
                        $("#detectPcCarousel .carousel-item").removeClass("d-flex justify-content-center w-100");
                    }
                    if (!hasRVPS && !hasRPPS && !thisPC && $("#EmcOrMobile").val() === "true") {
                        Dell.Components.RecentlyViewedProducts.checkAndHideIntegratedRvpsDivModified();
                    }
                    progressVal.style.width = '100%';
                    performance.mark("esup-homecards-end");
                    performance.measure("esup-homecards-load-" + appNameLoad, "esup-homecards-start", "esup-homecards-end");
                    setTimeout(function () {
                        progressEl.classList.add('d-none');
                        progressEl.classList.remove('d-flex');
                        var detectPcCarousel = document.getElementById('detectPcCarousel');
                        if (detectPcCarousel != null && detectPcCarousel != '') {
                            detectPcCarousel.classList.remove('d-none');
                        }
                        setTimeout(function () {
                            if (typeof(_detector) !== 'undefined') {
                                if (typeof(window.thisPCState) !== 'undefined') {
                                    _detector.trigger3rdPartyMap({
                                        SaIsAliveCallSent: window.thisPCState.SaIsAliveCallSent !== 'undefined' ? window.thisPCState.SaIsAliveCallSent : '',
                                        SaIsAliveResponseReceived: window.thisPCState.SaIsAliveResponseReceived !== 'undefined' ? window.thisPCState.SaIsAliveResponseReceived : '',
                                        SaIsAliveStatus: window.thisPCState.SaIsAliveStatus !== 'undefined' ? window.thisPCState.SaIsAliveStatus :'',
                                        SaIsAliveTimeOut: window.thisPCState.SaIsAliveTimeOut !== 'undefined' ? window.thisPCState.SaIsAliveTimeOut :'',
                                        SaIsAliveCallError: window.thisPCState.SaIsAliveCallError !== 'undefined' ? JSON.stringify(window.thisPCState.SaIsAliveCallError) : '',
                                        SaIsTagReceived: window.thisPCState.TagReceived !== 'undefined' ? window.thisPCState.TagReceived : '',
                                        SaVersion: JSON.parse(window.sessionStorage.getItem("clientVersion")),
                                        ExceptionCode: window.thisPCState.ExceptionCode !== 'undefined' ? window.thisPCState.ExceptionCode : ''
                                    });
                                }
                            }
                        }, 1000);
                    }, 1000);
                });
        };
        
        this.FilterRPVS = (recentlyViewedList, assets) => {
            let assetsServiceTags = assets.map(asset => { return asset.EsvcTag; });
            let filteredRvps = recentlyViewedList.filter(item => assetsServiceTags.indexOf(item.ServiceTag) === -1);
            return filteredRvps;
        };

        this.RemoveDetectTagFromRVPS = (list, serviceTag) => {
            let filteredList = list.filter(item => item.ServiceTag !== serviceTag);
            return filteredList;
        };

        this.RemoveDetectTagFromRPPS = (list, serviceTag) => {
            let filteredList = list.filter(item => item.EsvcTag !== serviceTag);
            return filteredList;
        };

        this.IntegratedRVPSLoaded = (rvpsCount) => {
            if (typeof window.OnIntegratedRVPSLoaded !== "undefined") {
                let callBackFun = window.OnIntegratedRVPSLoaded;
                let data = {
                    "rvpsCount": rvpsCount
                };
                callBackFun.call(this, data);
            }
        };

        this.IntegratedPCRVPSPurchaseProdsLoaded = (hasAnyRVPS, hasAnyPP, isPC, serviceTag, tagSource, SAerror) => {
            if (typeof window.OnPCRVPSPurchaseProdsLoaded !== "undefined") {
                let callBackFun = window.OnPCRVPSPurchaseProdsLoaded;
                let data = {
                    "rvpsOffered": hasAnyRVPS,
                    "purchaseProdOffered": hasAnyPP,
                    "thisPCOffered": isPC,
                    "serviceTag": serviceTag,
                    "tagSource": tagSource,
                    "error": SAerror
                };
                callBackFun.call(this, data);
            }
        };

        var init = this.Init;

        $(document).ready(function () {
            init();
        });

    }).apply(dell.Components.DetectProductRppsRvps);
})(jQuery, Dell = window.Dell || {});