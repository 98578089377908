var windowsOSValidationHelper = (function ($) {
    var windowsOsHelper = {};

    // HF - Enabling Detect My Prodcut button for Windows OS
    windowsOsHelper.enableDetectMyProduct = function () {
        var enableDetectMyProduct = false; // initializing the value to false to enable the button only for Windows OS. 

        // Checking Windows computer devices.
        if (navigator.platform.indexOf("Win") != -1) {
            enableDetectMyProduct = true;
        }

        // Checking Windows mobile devices.
        if (navigator.userAgent.match(/Windows Phone/i)
            || navigator.userAgent.match(/IEMobile/i)) {
            enableDetectMyProduct = true;
        }

        // Checking Windows RT mobile device.
        if (navigator.userAgent.match(/ARM/i)) {
            enableDetectMyProduct = false;
        }

        // check for the Windows 10S/N
        var win10S = $.getBrowserShorthandName();
        if (win10S == "10S") {
            enableDetectMyProduct = false;
        }

        return enableDetectMyProduct;
    };

    return windowsOsHelper;
}(jQuery));


(function($) {
    $.extend({
        getBrowserDetails: function() {
            var ua = navigator.userAgent,
                tem,
                M = ua.match(/(edge|opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
            if (/trident/i.test(M[1])) {
                tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
                return {
                    name: 'IE',
                    version: (tem[1] || '')
                };
            }
            if (M[1] === 'Chrome') {
                tem = ua.match(/\bOPR\/(\d+)/);
                if (tem != null) {
                    return {
                        name: 'Opera',
                        version: tem[1]
                    };
                }
                tem = ua.match(/Edge\/(\d+)/);
                if (tem != null) {
                    return {
                        name: 'Edge',
                        version: tem[1]
                    };
                }
            }
            M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
            if ((tem = ua.match(/version\/(\d+)/i)) != null) {
                M.splice(1, 1, tem[1]);
            }
            return {
                name: M[0] == "MSIE" ? "IE" : M[0],
                version: M[1]
            };

        },
        getBrowserShorthandName: function() {
            var browser = $.getBrowserDetails();
            var shorthandBrowserName;
            switch (browser.name) {
            case "IE":
                shorthandBrowserName = "IE";
                break;
            case "Edge":
                shorthandBrowserName = "ED";
                if (window.external && "getHostEnvironmentValue" in window.external) {
                    var skuKey = "os-sku";
                    var buildNumbers = ["178", "179"];
                    var result = window.external.getHostEnvironmentValue(skuKey);
                    if (result && (result.indexOf(buildNumbers[0]) !== -1 || result.indexOf(buildNumbers[1]) !== -1)) {
                        shorthandBrowserName = "10S";
                    }
                }
                break;
            case "Opera":
                shorthandBrowserName = "OP";
                break;
            case "Chrome":
                shorthandBrowserName = "CR";
                break;
            case "Safari":
                shorthandBrowserName = "SF";
                break;
            case "Firefox":
                shorthandBrowserName = "FF";
                break;
            default:
                shorthandBrowserName = browser.name;
                break;
            }
            return shorthandBrowserName;
        },
        isHttpsRequest: function() {
            return location.protocol.indexOf("https") >= 0;
        }
    });
}(jQuery));