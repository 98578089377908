(function ($, dell) {

    dell.Components = dell.Components || {};
    dell.Components.Eula = dell.Components.Eula || {};

    (function () {

        this.scandevice = (options) => {

            var model = {
                AppName: "Common",
                ContainerId: "",
                Intent: "N",
                ScanType: "",
                Type: "",
                SuccessCallback: "",
                AnalyticsCallback: "",
                ConsumerSuccessEventHandler: ""
            };

            model = $.extend(model, options);
            var commonEulaurl = this.Url();
            $.ajax({
                async: true,
                cache: false,
                url: commonEulaurl,
                type: 'post',
                headers: {
                    "X-Requested-With": "XMLHttpRequest"
                },
                data: model,
                success: function (data) {
                    var callBackFun = model.ConsumerSuccessEventHandler;
                    if (typeof window[callBackFun] === "function") {
                        callBackFun = window[callBackFun];
                        var returnObj = callBackFun.call(this, data, model.ContainerId);
                        if (returnObj !== undefined && returnObj === false) {
                            event.preventDefault();
                            return false;
                        }
                    } else {
                        console.log("ConsumerSuccessEventHandler function not exists" + callBackFun);
                    }
                }
            });
        };
        this.Url = () => {
            if (Dell.OnlineUtils !== 'undefined' && Dell.OnlineUtils.Lwp !== 'undefined') {
                let globalLwp = Dell.OnlineUtils.Lwp;
                let baseUrl = 'https://www.dell.com/support/components';
                if (Dell.ComponentLoader.baseUrls !== undefined && Dell.ComponentLoader.baseUrls["EulaApi"]) {
                    baseUrl =
                        `${Dell.ComponentLoader.baseUrls["EulaApi"]}/${globalLwp.language}-${globalLwp.country}/Eula/api/ScanDevice`;
                }
                return baseUrl;
            }
            return '';
        };

        var scandevice = this.scandevice;
        $(document).off("click", '#btnSupportAssist').on("click", '#btnSupportAssist', function () {
            $(".spinnerDetectPc").removeClass("d-none");
            var obj = {
                ContainerId: "eulamodalContainer",
                ScanType: "TMC",
                Type: "model",
                SuccessCallback: "configEulaScanSuccess",
                AnalyticsCallback: "configOmnitureTracker",
                ConsumerSuccessEventHandler: "configSuccessEventHandler"
            };
            scandevice(obj);
        });


        window.configEulaScanSuccess = (flag, servicetag) => {
            if (flag === true && servicetag !== null) {
                $("#eulamodalContainer").modal('hide');
                let detectPc = Dell.Components.DetectProduct;
                let detectResponse = {
                    serviceTag: servicetag,
                    supportAssistInstalled: true
                };
                if (typeof window.DetectEulaScanSuccess !== "undefined") {
                    setTimeout(function () {
                        let callBackFun = window.DetectEulaScanSuccess;
                        let returnObj = callBackFun.call(this, detectResponse);
                        if (returnObj !== undefined && returnObj === false) {
                            event.preventDefault();
                        }
                    }, 1200);
                }
                detectPc.getProductInfo(detectResponse);
            }
        };

        window.configSuccessEventHandler = (data) => {
            if (data !== null) {
                $("#eulamodalContainer .modal-body").html('').html(data);
                $("#eulamodalContainer").modal('show');
            }
        };

        window.configOmnitureTracker = (description, code) => {
            if (code === '005') {
                //close Eula popup
                config_cancel_eula();
            }
            else if (code === '009') {
                //close Eula popup
                dellmetricsTrack_prop20_format("222.900.111.002", "detectpc-eula");
            }
            else if (code === '001') {
                //SA is Installed show scan data 
            }
        };

        window.config_cancel_eula = () => {
            $("#eulamodalContainer .modal-body").html('');
            $("#eulamodalContainer").modal('hide');
        };

    }).apply(dell.Components.Eula);
})(jQuery, Dell = window.Dell || {});