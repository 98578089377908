(function (jq, dell) {
    dell.Components = dell.Components || {};
    dell.Components.DetectProduct = dell.Components.DetectProduct || {};
    (function () {
        var detectProductContent = {};
        this.enableDetectMyProduct = windowsOSValidationHelper.enableDetectMyProduct();
        this.detectProductPromise = $.Deferred();

        this.getnoSupportAssistHtml = (response) => {
            let nosaFvVal = $('#NosaFv').val();
            if (nosaFvVal === "false") {
                let noSupportAssistHtml = `<div class="product-container"><div class="sa-recommended-slide"><span class="product-badge badge badge-pill badge-success">${response.recommendedSaText}</span><p class="mb-xl-2 w-100 mb-4">${response.supportAssistTextV2}</p><button class="btn btn-outline-primary btn-sm" id="btnSupportAssist">${response.downloadInstallSaText}</button></div></div>`;
                this.renderView(noSupportAssistHtml);
                $("#btnSupportAssist").unbind("click").click(function () { Dell.Components.DetectProduct.onDownloadInstallSaClick(); });
            } else {
                let noSupportAssistHtmlFv = `<div class="text-center"><span class="product-badge badge badge-pill badge-success">${response.recommendedSaText}</span><p class="mb-4">${response.supportAssistTextV2}</p><button class="btn btn-outline-primary btn-sm" id="btnSupportAssist">${response.downloadInstallSaText}</button></div>`;
                this.renderView(noSupportAssistHtmlFv);
                $("#firstCarslItem").removeClass("carousel-item active");
                $("#btnSupportAssist").unbind("click").click(function () { Dell.Components.DetectProduct.onDownloadInstallSaClick(); });
            }
        };

        this.getOsNotSupportedtHtml = (response) => {
            let osNotSupportedtHtml = `<div class="alert alert-primary align-items-left mt-1" role="alert" id="notSupportedOS"><span class="info-icon"><svg class="dti drivers-info fill-primary mr-2"><svg viewBox="0 0 28 28"><path d="M13.9993 0.666016C6.63935 0.666016 0.666016 6.63935 0.666016 13.9993C0.666016 21.3593 6.63935 27.3327 13.9993 27.3327C21.3593 27.3327 27.3327 21.3593 27.3327 13.9993C27.3327 6.63935 21.3593 0.666016 13.9993 0.666016ZM15.3327 20.666H12.666V12.666H15.3327V20.666ZM15.3327 9.99935H12.666V7.33268H15.3327V9.99935Z" fill="#006BBD"></path></svg></svg></span><span>${response.osNotSupported}</span></div>`;
            this.renderView(osNotSupportedtHtml);
            var selector = '#detectPcCarousel .carousel-item';
            if ($(selector).length == 1) {
                $(selector).addClass("d-flex justify-content-center w-100");
            }
            else {
                $(selector).removeClass("d-flex justify-content-center w-100");
            }
        };

        this.getBrowserNotSupportedHtml = (response) => {
            let browserNotSupportedtHtml = '';
            if (browserName.toLowerCase() == 'trident')
                browserNotSupportedtHtml = `<div class="alert alert-primary align-items-left mt-1" role="alert" id="notSupportedBrowser"><span class="info-icon"><svg class="dti drivers-info fill-primary mr-2"><svg viewBox="0 0 28 28"><path d="M13.9993 0.666016C6.63935 0.666016 0.666016 6.63935 0.666016 13.9993C0.666016 21.3593 6.63935 27.3327 13.9993 27.3327C21.3593 27.3327 27.3327 21.3593 27.3327 13.9993C27.3327 6.63935 21.3593 0.666016 13.9993 0.666016ZM15.3327 20.666H12.666V12.666H15.3327V20.666ZM15.3327 9.99935H12.666V7.33268H15.3327V9.99935Z" fill="#006BBD"></path></svg></svg></span><span>${response.unSupportedBrowser}</span></div>`;
            else if (browserName.toLowerCase() == 'edg' || browserName.toLowerCase() == 'edge')
                browserNotSupportedtHtml = `<div class="alert alert-primary align-items-left mt-1" role="alert" id="notSupportedBrowser"><span class="info-icon"><svg class="dti drivers-info fill-primary mr-2"><svg viewBox="0 0 28 28"><path d="M13.9993 0.666016C6.63935 0.666016 0.666016 6.63935 0.666016 13.9993C0.666016 21.3593 6.63935 27.3327 13.9993 27.3327C21.3593 27.3327 27.3327 21.3593 27.3327 13.9993C27.3327 6.63935 21.3593 0.666016 13.9993 0.666016ZM15.3327 20.666H12.666V12.666H15.3327V20.666ZM15.3327 9.99935H12.666V7.33268H15.3327V9.99935Z" fill="#006BBD"></path></svg></svg></span><span>${response.unSupportedBrowserEdge}</span></div>`;
            this.renderView(browserNotSupportedtHtml);
            var selector = '#detectPcCarousel .carousel-item';
            if ($(selector).length == 1) {
                $(selector).addClass("d-flex justify-content-center w-100");
                $('#notSupportedBrowser').css('display', 'table-cell');
            }
            else {
                $(selector).removeClass("d-flex justify-content-center w-100");
            }
        };

        this.getErrorHtml = () => {
            let html = `<div class="alert alert-primary align-items-left mt-1" role="alert"><span><svg class="dti mr-2 mb-1"><svg viewBox="0 0 28 28"><path d="M13.9993 0.666016C6.63935 0.666016 0.666016 6.63935 0.666016 13.9993C0.666016 21.3593 6.63935 27.3327 13.9993 27.3327C21.3593 27.3327 27.3327 21.3593 27.3327 13.9993C27.3327 6.63935 21.3593 0.666016 13.9993 0.666016ZM15.3327 20.666H12.666V12.666H15.3327V20.666ZM15.3327 9.99935H12.666V7.33268H15.3327V9.99935Z" fill="#006BBD"></path></svg></svg></span><span>${window.ErrorIdentifyingYourSystem}</span></div>`;
            this.renderView(html);
            var selector = '#detectPcCarousel .carousel-item';
            if ($(selector).length == 1) {
                $(selector).addClass("d-flex justify-content-center w-100");
            }
            else {
                $(selector).removeClass("d-flex justify-content-center w-100");
            }
        };

        this.getProductInfoHtml = (response) => {
            var thisPcEvent = new CustomEvent("thisPcData", { detail: { data: response } });
            window.dispatchEvent(thisPcEvent);

            let productInfoHtml = `<div class="product-container"><div class="product-image"><a id="anc_ProductInfoHtml_Stet" href="${response.detectProductContent.serviceTagTargetUrl}"><img src="${response.productInfo.imageUrl}" style="max-width: 90px;height: auto;" title="${response.productInfo.name}" alt="${response.productInfo.name} image"/></a></div><div class="product-details"><span class="product-badge badge badge-pill badge-success">${response.detectProductContent.thisPcText}</span><p class="product-name"><a id="anc_ProductInfoHtml_Stet1" href="${response.detectProductContent.serviceTagTargetUrl}">${response.productInfo.name}</a></p><p class="product-identifier">${response.detectProductContent.serviceTag} ${response.productInfo.serviceTag}</p></div></div>`;
            this.renderView(productInfoHtml);
            $("#anc_ProductInfoHtml_Stet,#anc_ProductInfoHtml_Stet1").unbind("click").click(function () { Dell.Components.DetectProduct.detectPcCallBack(response.productInfo.serviceTag, response.productInfo.encryptedTag); });
            var selector = '#detectPcCarousel .carousel-item';
            if ($(selector).length == 1) {
                $(selector).addClass("d-flex justify-content-center w-100");
            }
            else {
                $(selector).removeClass("d-flex justify-content-center w-100");
            }
            this.supportAssistFinish(null);
        };

        this.onDownloadInstallSaClick = () => {
            if (typeof window.DownloadInstallClickCallBack !== "undefined") {
                let callBackFun = window.DownloadInstallClickCallBack;
                let returnObj = callBackFun.call(this, null);
                if (returnObj !== undefined && returnObj === false) {
                    event.preventDefault();
                }
            }
        };

        this.onDetectProductNextClick = () => {
            if (typeof window.DetectProductNextClickCallBack !== "undefined") {
                let callBackFun = window.DetectProductNextClickCallBack;
                let returnObj = callBackFun.call(this, null);
                if (returnObj !== undefined && returnObj === false) {
                    event.preventDefault();
                }
            }
        };

        this.detectPcCallBack = (serviceTag, encryptedTag) => {
            if (typeof window.DetectProductCallBack !== "undefined") {
                let callBackFun = window.DetectProductCallBack;
                let data = {
                    "serviceTag": serviceTag,
                    "encryptedTag": encryptedTag
                };
                let returnObj = callBackFun.call(this, data);
                if (returnObj !== undefined && returnObj === false) {
                    event.preventDefault();
                }
            }
        };

        this.supportAssistStart = () => {
            if (typeof window.SupportAssistStartComp !== "undefined") {
                let callBackFun = window.SupportAssistStartComp;
                let returnObj = callBackFun.call(this, null);
                if (returnObj !== undefined && returnObj === false) {
                    event.preventDefault();
                }
            }
        };

        this.supportAssistFinish = (err) => {
            if (typeof window.SupportAssistFinishComp !== "undefined") {
                let callBackFun = window.SupportAssistFinishComp;
                let returnObj = callBackFun.call(this, err);
                if (returnObj !== undefined && returnObj === false) {
                    event.preventDefault();
                }
            }
        };
        this.getClientTime = () => {
            const now = new Date();
            const utcSeconds = now.getTime() + (now.getTimezoneOffset() * 60);
            return Math.round(utcSeconds / 1000);
        };
        this.Url = () => {
            if (Dell.OnlineUtils !== 'undefined' && Dell.OnlineUtils.Lwp !== 'undefined') {
                let baseUrl = 'https://www.dell.com/support/components';
                if (Dell.ComponentLoader.baseUrls !== undefined && Dell.ComponentLoader.baseUrls["DetectProductApi"]) {
                    baseUrl = Dell.ComponentLoader.baseUrls["DetectProductApi"];
                }
                let urls = {
                    baseUrl: baseUrl,
                    getCmsContent: 'detectproduct/cmstokensainfo?appName=' + Dell.ComponentLoader.appNames["detect-product"] + '&expiry=' + this.getClientTime(),
                    getProductInfo: 'detectproduct/productinfo',
                    logSAFailure: 'detectproduct/sadetectfailure'
                };
                return urls;
            }
            return '';
        };

        this.IsSaSupportedOs = () => {
            let isSupportedOs = true;
            if (typeof window.detectPcSaSupportedOs != 'undefined') {
                isSupportedOs = window.detectPcSaSupportedOs;
            }
            return isSupportedOs;
        };

        this.IsSaUnSupportedBrowser = () => {
            if (typeof window.detectPcSaUnSupportedBrowser != 'undefined' && window.detectPcSaUnSupportedBrowser == 1) {
                return true;
            }
            return false;
        };

        this.makeAjaxCall = (url, methodType) => {
            let ajaxData = {};
            if (url.indexOf("/sadetectfailure") > -1) {
                ajaxData = {
                    url: url,
                    method: methodType,
                    contentType: "application/json;",
                    cache: false,
                    async: true
                };
            }
            else {
                ajaxData = {
                    url: url,
                    method: methodType,
                    dataType: "json",
                    contentType: "application/json;",
                    cache: false,
                    async: true
                };
            }

            if (url.indexOf("//.dell.com") === -1) {
                ajaxData.xhrFields = {
                    withCredentials: true
                };
            }

            return jq.ajax(ajaxData);
        };

        this.getSAVerionProp13 = () => {
            if (sessionStorage['clientVersion']) {
                var getSAVerion = JSON.parse(window.sessionStorage.getItem("clientVersion"));
                if (typeof getSAVerion !== "undefined" && getSAVerion !== null) {
                    return ('|sv-' + getSAVerion);
                } else
                    return "";
            } else
                return "";
        };

        var getProductInfoHtml1 = this.getProductInfoHtml;
        var getErrorHtml1 = this.getErrorHtml;
        var getOsNotSupportedtHtml1 = this.getOsNotSupportedtHtml;
        var getnoSupportAssistHtml1 = this.getnoSupportAssistHtml;
        var browserName = window.unSupportedBrowserName;
        var getBrowserNotSupportedHtml = this.getBrowserNotSupportedHtml;
        var IsSaUnSupportedBrowser1 = this.IsSaUnSupportedBrowser;
        var IsSaSupportedOs1 = this.IsSaSupportedOs;
        var GetSAVerionProp13 = this.getSAVerionProp13;

        this.getProductInfo = (detectResponse) => {
            this.detectProductPromise.resolve(detectResponse);
            var isSaUnSupportedBrowser = IsSaUnSupportedBrowser1();
            var isSaSupportedOs = IsSaSupportedOs1();
            var getSAVerionProp = GetSAVerionProp13();
            if (isSaSupportedOs !== undefined && !isSaSupportedOs) {
                performance.mark("esup-thispc-end");
                var measureTitleOs = "esup-thispc-load-" + Dell.ComponentLoader.appNames["detect-product"] + "-" + "osnotsupported"
                performance.measure(measureTitleOs, "esup-thispc-start", "esup-thispc-end");
                getOsNotSupportedtHtml1(detectProductContent);
            } else if (isSaUnSupportedBrowser != undefined && isSaUnSupportedBrowser) {
                performance.mark("esup-thispc-end");
                var measureTitleBrowser = "esup-thispc-load-" + Dell.ComponentLoader.appNames["detect-product"] + "-" + "browsernotsupported"
                performance.measure(measureTitleBrowser, "esup-thispc-start", "esup-thispc-end");
                getBrowserNotSupportedHtml(detectProductContent);
            } else {
                if (detectResponse.supportAssistInstalled) {
                    let urlPath = this.Url();
                    let saUrl = `${urlPath.baseUrl}/${urlPath.getProductInfo}/${detectResponse.serviceTag}/${Dell.ComponentLoader.appNames["detect-product"]}`;
                    this.makeAjaxCall(saUrl, "GET")
                        .done(function (response) {
                            if (typeof (Storage) !== "undefined") {
                                sessionStorage.setItem("thispc", JSON.stringify(response.productInfo));
                            }
                            if (response !== null && response.productInfo !== null) {
                                if (response.productInfo.failed) {                                   
                                    dellmetricsTrack_prop20_format("222.200.111.222", "satagvalid|failure|thispc" + getSAVerionProp);
                                } else {
                                    dellmetricsTrack_prop20_format("222.200.111.222", "satagvalid|success|thispc" + getSAVerionProp);
                                }
                            }
                            getProductInfoHtml1(response);
                        }).fail(function () {
                            dellmetricsTrack_prop20_format("222.200.111.222", "satagvalid|failure|apierror|thispc" + getSAVerionProp);
                            performance.mark("esup-thispc-end");
                            var measureTitleFail = "esup-thispc-load-" + "fail-" + Dell.ComponentLoader.appNames["detect-product"]
                            performance.measure(measureTitleFail, "esup-thispc-start", "esup-thispc-end");
                            getErrorHtml1();
                            console.error('detect product api failed');
                        });
                } else {
                    performance.mark("esup-thispc-end");
                    var measureTitleNosa = "esup-thispc-load-" + Dell.ComponentLoader.appNames["detect-product"] + "-" + "nosa"
                    performance.measure(measureTitleNosa, "esup-thispc-start", "esup-thispc-end");
                    getnoSupportAssistHtml1(detectProductContent);
                }
            }

        };        

        this.logSaFailure = (error, methodName) => {
            try {
                let errorType = methodName + " failed";
                let errorCode = "failure";
                if (typeof error !== 'undefined' && error !== null) {
                    errorType = error.type !== 'undefined' ? error.type : "";
                    errorCode = error.errorCode !== 'undefined' ? error.errorCode : "";
                }
                console.log('error type new...', errorType + ' ' + errorCode);
                let urlPath = this.Url();
                let saFailureLogUrl = `${urlPath.baseUrl}/${urlPath.logSAFailure}`;
                let urlString = saFailureLogUrl + '?errorType=' + errorType + '&errorCode=' + errorCode + '&methodName=' + methodName;
                let tag = window.localStorage.getItem("tagFromSA");
                if (typeof tag !== 'undefined' && tag)
                    urlString += "&sTag=" + tag;
                this.makeAjaxCall(urlString, "GET")
                    .done(function (response) {
                        console.log("logsa api passed");
                    }).fail(function (error) {
                        console.error('logsa api failed');
                    });
            } catch (err) {
                console.error('Error in posting scan error' + err);
            }
        };

        let getProductInformation = this.getProductInfo;
        var supportAssistStart1 = this.supportAssistStart;
        let logSupportAssistFailure = this.logSaFailure;
        var supportAssistFinish1 = this.supportAssistFinish;

        this.callSupportAssist = (clientApi) => {
            let detectResponse = {};
            supportAssistStart1();
            clientApi.findService(function () {
                if (sessionStorage.getItem('tagFromSA') == null)
                    console.error({ Source: 'thispc', Type: 'isalive_success_without_ST', clientVersion: sessionStorage.getItem('clientVersion'), Message: 'isAlive call response without service tag' });
                detectResponse = {
                    serviceTag: sessionStorage.getItem('tagFromSA'),
                    supportAssistInstalled: true,
                    tagSource: "sa"
                };
                getProductInformation(detectResponse);
            },
                function (apiError) {
                    detectResponse = {
                        serviceTag: '',
                        supportAssistInstalled: false,
                        tagSource: "",
                        SAerror: apiError
                    };
                    //supportAssistFinish1(apiError);
                    getProductInformation(detectResponse);
                    logSupportAssistFailure(apiError, 'FindService');
                });

        };


        this.setSaToken = (globalObj, response) => {
            globalObj.tokenExpires.setValue(response.dsdToken.expiration);
            globalObj.isAliveToken.setValue(response.dsdToken.isAliveToken);
            globalObj.getClientSystemInfoToken.setValue(response.dsdToken.clientSystemInfoToken);
            if (IsSaSupportedOs1() && !IsSaUnSupportedBrowser1()) {
                this.callSupportAssist(globalObj);
            } else {
                let detectResponse = {};
                detectResponse = {
                    serviceTag: '',
                    supportAssistInstalled: false,
                    tagSource: ""
                };
                getProductInformation(detectResponse);
                logSupportAssistFailure(null, 'BrowserOrOS_NotSupported');
            }

        };

        let setSupportAssistToken = this.setSaToken;
        var detectProductPromise = this.detectProductPromise;

        this.callSa = () => {
            let urlPath = this.Url();
            let url = `${urlPath.baseUrl}/${urlPath.getCmsContent}`;
            this.makeAjaxCall(url, "GET")
                .done(function (response) {
                    detectProductContent = response.detectProductContent;
                    return response;
                }).done(function (response) {
                    if (response.productInfo !== null && response.productInfo.serviceTag !== null && response.productInfo.serviceTag !== "") {
                        if (typeof (Storage) !== "undefined") {
                            sessionStorage.setItem("thispc", JSON.stringify(response.productInfo));
                        }
                        supportAssistStart1();
                        let detectResponse = {
                            serviceTag: response.productInfo.serviceTag,
                            supportAssistInstalled: true,
                            tagSource: "cookie"
                        };
                        detectProductPromise.resolve(detectResponse);
                        getProductInfoHtml1(response);
                    } else {
                        try {
                            loadFile(response.dsdToken.supportAssistConfigUrl,
                                function () {
                                    loadFile(response.dsdToken.dsdApiUrlNoJQ,
                                        function () {
                                            let globalEsc = new window.clientApi.eSupportClientApi();
                                            setSupportAssistToken(globalEsc, response);
                                        });
                                });
                        } catch (ex) {
                            let detectResponse = {
                                serviceTag: '',
                                supportAssistInstalled: false,
                                tagSource: ""
                            };
                            detectProductPromise.resolve(detectResponse);
                            getErrorHtml1();
                            console.error("DetectPc Error :- ", ex);
                        }
                    }
                }).fail(function () {
                    let detectResponse = {
                        serviceTag: '',
                        supportAssistInstalled: false,
                        tagSource: ""
                    };
                    detectProductPromise.resolve(detectResponse);
                    getErrorHtml1();
                    console.error('detect product api failed');
                });
        };

        this.renderView = (htmlString) => {
            var divAutoDetectPc = document.getElementById("divAutoDetectPc");
            if (divAutoDetectPc !== undefined && divAutoDetectPc !== null) {
                divAutoDetectPc.innerHTML = htmlString;
            }
        };

    }).apply(dell.Components.DetectProduct);
})(jQuery, Dell = window.Dell || {});